import React, { Component } from "react"
import styles from "./about.module.css"
import Icon from "../img/downloading.svg"
import { graphql } from "gatsby"
import ContactForm from "../components/contact_form"

class About extends Component {
  render() {
    let text = this.props.data.markdownRemark.html
    return (
      <div className={styles.aboutContent + " main-content"}>
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
        <ContactForm />
        <div className={styles.downloadWrapper}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={"/Konzept_ausfuhrlich.pdf"}
            className={styles.downloadBox + " card"}
          >
            Konzept ausführlich
            <Icon className={styles.downloadIcon} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={"/Konzept_kurz.pdf"}
            className={styles.downloadBox + " card"}
          >
            Konzept kurz
            <Icon className={styles.downloadIcon} />
          </a>
        </div>
      </div>
    )
  }
}

export default About

export const pageQuery = graphql`
  query aboutQuery {
    markdownRemark(frontmatter: { type: { eq: "about" } }) {
      html
    }
  }
`
