import React, { Component } from "react";
import styles from "./form.module.css"
import Button from "../buttons/button"

class ContactForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            comment: "",
            email: "",
        }
    }

    handleSubmit = event => {
        event.preventDefault()
        // let url = "https://blog.menon.education/wp-admin/admin-ajax.php?action=send_form"
        // let data = this.state

        // fetch(url, {
        //     method: 'POST',
        //     body: JSON.stringify(data)
        // }).then(response => console.log(response.json()))
    }

    handleInputChanged = event => {
        const target = event.target
        const value = target.value
        const name = target.name
        if (value !== "" && value !== null) {
            target.classList.add(styles.notEmpty)
        } else {
            target.classList.remove(styles.notEmpty)
        }


        this.setState({
            [name]: value,
        })
    }

    render() {
        return <div className={styles.form}>
            <form onSubmit={this.handleSubmit}>
                <div className={styles.inputWrapper} >
                    <input type="text" name="name" onChange={this.handleInputChanged} />
                    <label>
                        Name
                    </label>
                </div>
                <div className={styles.inputWrapper} >
                    <input type="email" name="email" onChange={this.handleInputChanged} />
                    <label>
                        E-Mail
                    </label>
                </div>
                <div className={styles.inputWrapper} >
                    <textarea name="comment" onChange={this.handleInputChanged} />
                    <label>
                        Kommentar
                    </label>
                </div>
                <Button type="submit" buttonText="Senden" />
            </form>
        </div>
    }
}

export default ContactForm